import { Injectable } from '@angular/core';
import { arrayOf, normalize } from 'normalizr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ArrayStrategy, objectToSearchParams } from '../../../api/api.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction as Action } from '../../interfaces';
import { assignSchemaEntity } from '../../shared/assign';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { OpenShiftSchema, ScheduleSchema } from '../../shared/schemas';
import { ScheduleScope } from '../schedule/schedule.model';
import { OpenShiftsLoadRequest } from './open-shift.model';

@Injectable()
export class OpenShiftApi {
  private endpoint = 'open_shifts/';

  public constructor(private gateway: ApiGateway) {}

  public load(requestData: OpenShiftsLoadRequest, dispatchStart?: Action): Observable<any> {
    const searchParams = {
      ...requestData,
    };
    const search = objectToSearchParams(searchParams, ArrayStrategy.MULTIPLE_PARAMS);

    return this.gateway
      .get(
        this.endpoint,
        {
          params: search,
        },
        dispatchStart,
      )
      .pipe(
        map((res) => reformatListResponse('OpenShift', res)),
        map((data) => normalize(data, arrayOf(OpenShiftSchema), { assignEntity: assignSchemaEntity })),
      );
  }

  public add(scheduleData, period, dispatchStart?: Action): Observable<any> {
    const search = objectToSearchParams(period);

    return this.gateway.post(this.endpoint, scheduleData, { params: search }, dispatchStart).pipe(
      map((res) => reformatListResponse('OpenShift', res)),
      map((data) => normalize(data, arrayOf(OpenShiftSchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public update(occurrenceId, openShift, period, scope, dispatchStart?: Action): Observable<any> {
    const search = objectToSearchParams(period);

    return this.gateway
      .put(`${this.endpoint}${occurrenceId}/${scope}`, openShift, { params: search }, dispatchStart)
      .pipe(
        map((res) => reformatListResponse('OpenShift', res)),
        map((data) => normalize(data, arrayOf(OpenShiftSchema), { assignEntity: assignSchemaEntity })),
      );
  }

  public fetch(occurrenceId, dispatchStart?: Action): Observable<any> {
    return this.gateway.get(this.endpoint + occurrenceId, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('OpenShift', res)),
      map((data) => normalize(data, OpenShiftSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public remove(occurrenceId, scope: ScheduleScope, dispatchStart?: Action): Observable<any> {
    return this.gateway.delete(`${this.endpoint}${occurrenceId}/${scope}`, undefined, dispatchStart);
  }

  public takeShift(occurrenceId, dispatchStart?: Action): Observable<any> {
    return this.gateway.put(this.endpoint + occurrenceId + '/take', undefined, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Roster', res)),
      map((data) => normalize(data, ScheduleSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public rejectShift(openShiftData, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint + 'reject', openShiftData, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('OpenShift', res)),
      map((data) => normalize(data, OpenShiftSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public requestShift(occurrenceId: string, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint + occurrenceId + '/request', {}, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('OpenShift', res)),
      map((data) => normalize(data, OpenShiftSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public assign(occurrenceId, scheduleData, dispatchStart?: Action): Observable<any> {
    return this.gateway.put(this.endpoint + occurrenceId + '/assign', scheduleData, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Roster', res)),
      map((data) => normalize(data, ScheduleSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public multiAssign(occurrenceId, scheduleData, dispatchStart?: Action): Observable<any> {
    return this.gateway
      .put(this.endpoint + occurrenceId + '/multi_assign', scheduleData, undefined, dispatchStart)
      .pipe(
        map((res) => reformatListResponse('Roster', res)),
        map((data) => normalize(data, arrayOf(ScheduleSchema), { assignEntity: assignSchemaEntity })),
      );
  }

  public withdraw(occurrenceId: string, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint + occurrenceId + '/withdraw', {}, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('OpenShift', res)),
      map((data) => normalize(data, OpenShiftSchema, { assignEntity: assignSchemaEntity })),
    );
  }
}
