import { UnsafeAction as Action } from '../../interfaces';
import { ScheduleScope, ScheduleUpdateEvent } from '../schedule/schedule.model';
import { LoadOpenShiftsSuccessAction, OpenShiftsLoadRequest } from './open-shift.model';
import { OptimizedStoreItemData } from '../../store/schedule/schedule.n.model';

const ACTION_PREFIX = '[OpenShift]';

export const openShiftActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  REMOVE: `${ACTION_PREFIX} Remove`,
  REMOVE_SUCCESS: `${ACTION_PREFIX} Remove Success`,

  TAKE_SHIFT: `${ACTION_PREFIX} Take Shift`,
  TAKE_SHIFT_SUCCESS: `${ACTION_PREFIX} Take Shift Success`,
  TAKE_SHIFT_FAILED: `${ACTION_PREFIX} Take Shift Failed`,

  REJECT_SHIFT: `${ACTION_PREFIX} Reject Shift`,
  REJECT_SHIFT_SUCCESS: `${ACTION_PREFIX} Reject Shift Success`,
  REJECT_SHIFT_FAILED: `${ACTION_PREFIX} Reject Shift Failed`,

  REQUEST_SHIFT: `${ACTION_PREFIX} Request Shift`,
  REQUEST_SHIFT_SUCCESS: `${ACTION_PREFIX} Request Shift Success`,
  REQUEST_SHIFT_FAILED: `${ACTION_PREFIX} Request Shift Failed`,

  WITHDRAW: `${ACTION_PREFIX} Withdraw`,
  WITHDRAW_SUCCESS: `${ACTION_PREFIX} Withdraw Success`,
  WITHDRAW_FAILED: `${ACTION_PREFIX} Withdraw Failed`,

  ASSIGN: `${ACTION_PREFIX} Assign Shift`,
  ASSIGN_SUCCESS: `${ACTION_PREFIX} Assign Shift Success`,

  MULTI_ASSIGN: `${ACTION_PREFIX} Multi Assign Shift`,
  MULTI_ASSIGN_SUCCESS: `${ACTION_PREFIX} Multi Assign Shift Success`,
};

export class OpenShiftAction {
  public static load(requestData): Action {
    return {
      type: openShiftActionType.LOAD,
    };
  }

  public static loadSuccess(openShift, requestData: OpenShiftsLoadRequest): LoadOpenShiftsSuccessAction {
    return {
      type: openShiftActionType.LOAD_SUCCESS,
      payload: openShift,
      requestData,
    };
  }

  public static loadFailed(err): Action {
    return {
      type: openShiftActionType.LOAD_FAILED,
      payload: err,
    };
  }

  public static add(openShiftData, loadingId): Action {
    return {
      type: openShiftActionType.ADD,
      payload: { openShiftData, loadingId },
    };
  }

  public static addSuccess(openShiftResponse, loadingId): Action {
    return {
      type: openShiftActionType.ADD_SUCCESS,
      payload: { ...openShiftResponse, loadingId },
    };
  }

  public static update(openShiftData, occurrenceId, scope: ScheduleScope, loadingId): Action {
    return {
      type: openShiftActionType.UPDATE,
      payload: { openShiftData, occurrenceId, scope, loadingId },
    };
  }

  public static updateSuccess(scheduleResponse, loadingId, recurring: boolean = false): Action {
    return {
      type: openShiftActionType.UPDATE_SUCCESS,
      payload: { ...scheduleResponse, loadingId, recurring },
    };
  }

  public static fetch(id: any) {
    return {
      type: openShiftActionType.FETCH,
      payload: id,
    };
  }

  public static fetchSuccess(response) {
    return {
      type: openShiftActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  public static fetchFailed(id: string, err) {
    return {
      type: openShiftActionType.FETCH_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  public static remove(occurrenceId: string, scope: ScheduleScope, optimizedData: OptimizedStoreItemData) {
    return {
      type: openShiftActionType.REMOVE,
      payload: { occurrenceId, scope, optimizedData },
    };
  }

  public static removeSuccess(id: string) {
    return {
      type: openShiftActionType.REMOVE_SUCCESS,
      payload: id,
    };
  }

  public static takeShift(id: string, teamId: string) {
    return {
      type: openShiftActionType.TAKE_SHIFT,
      payload: { id, teamId },
    };
  }

  public static takeShiftSuccess(id: string, response, updateEvent: ScheduleUpdateEvent) {
    return {
      type: openShiftActionType.TAKE_SHIFT_SUCCESS,
      payload: {
        ...response,
        occurrenceId: id,
      },
      updateEvent,
    };
  }

  public static takeShiftFailed(id: string, err) {
    return {
      type: openShiftActionType.TAKE_SHIFT_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  public static assign(scheduleData, occurrenceId, loadingId): Action {
    return {
      type: openShiftActionType.ASSIGN,
      payload: { scheduleData, occurrenceId, loadingId },
    };
  }

  public static assignSuccess(scheduleResponse, loadingId, updateEvent: ScheduleUpdateEvent): Action {
    return {
      type: openShiftActionType.ASSIGN_SUCCESS,
      payload: { ...scheduleResponse, loadingId },
      updateEvent,
    };
  }

  public static multiAssign(scheduleData, occurrenceId, loadingId): Action {
    return {
      type: openShiftActionType.MULTI_ASSIGN,
      payload: { scheduleData, occurrenceId, loadingId },
    };
  }

  public static multiAssignSuccess(scheduleResponse, loadingId, updateEvent: ScheduleUpdateEvent): Action {
    return {
      type: openShiftActionType.MULTI_ASSIGN_SUCCESS,
      payload: { ...scheduleResponse, loadingId },
      updateEvent,
    };
  }

  public static rejectShift(openShiftData): Action {
    return {
      type: openShiftActionType.REJECT_SHIFT,
      payload: openShiftData,
    };
  }

  public static rejectShiftSuccess(id: string, response) {
    return {
      type: openShiftActionType.REJECT_SHIFT_SUCCESS,
      payload: {
        ...response,
        occurrenceId: id,
      },
    };
  }

  public static rejectShiftFailed(id: string, err) {
    return {
      type: openShiftActionType.REJECT_SHIFT_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  public static requestShift(openShiftData): Action {
    return {
      type: openShiftActionType.REQUEST_SHIFT,
      payload: openShiftData,
    };
  }

  public static requestShiftSuccess(id: string, response) {
    return {
      type: openShiftActionType.REQUEST_SHIFT_SUCCESS,
      payload: {
        ...response,
        occurrenceId: id,
      },
    };
  }

  public static requestShiftFailed(id: string, err) {
    return {
      type: openShiftActionType.REQUEST_SHIFT_FAILED,
      payload: {
        id,
        err,
      },
    };
  }
  public static withdraw(openShiftData): Action {
    return {
      type: openShiftActionType.WITHDRAW,
      payload: openShiftData,
    };
  }

  public static withdrawSuccess(id: string, response) {
    return {
      type: openShiftActionType.WITHDRAW_SUCCESS,
      payload: {
        ...response,
        occurrenceId: id,
      },
    };
  }

  public static withdrawFailed(id: string, err) {
    return {
      type: openShiftActionType.WITHDRAW_FAILED,
      payload: {
        id,
        err,
      },
    };
  }
}
