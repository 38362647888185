import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import uniqueId from 'lodash-es/uniqueId';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import u from 'updeep';

import { AppState } from '../../index';
import { getSelectedSchedulePeriod } from '../../page-filters/page-filters.helper';
import { ScheduleFilterPeriod } from '../../page-filters/page-filters.model';
import { undo } from '../../shared/undo/undoAction';
import { OptimizedStoreItemData } from '../../store/schedule/schedule.n.model';
import { ScheduleScope } from '../schedule/schedule.model';
import { OpenShiftAction } from './open-shift.action';
import { OpenShiftApi } from './open-shift.api';
import { OpenShiftModel, OpenShiftMultiAssignRequestModel, OpenShiftsLoadRequest } from './open-shift.model';

@Injectable()
export class OpenShiftService {
  public constructor(
    private store: Store<AppState>,
    private api: OpenShiftApi,
  ) {}

  /**
   * Determine the period to update on a save call
   * @param date
   * @returns {Observable<any>}
   */
  private getSavePeriod(date): Observable<{ minDate: string; maxDate: string }> {
    return this.store.select(getSelectedSchedulePeriod).pipe(
      first(),
      map((filterPeriod: ScheduleFilterPeriod) => ({
        minDate: date,
        maxDate: filterPeriod.maxDate < date ? date : filterPeriod.maxDate,
      })),
    );
  }

  public load(requestData: OpenShiftsLoadRequest, updateStore = true) {
    return this.api.load(requestData, OpenShiftAction.load(requestData)).pipe(
      map((response) => {
        if (updateStore) {
          this.store.dispatch(OpenShiftAction.loadSuccess(response, requestData));
        }

        return response;
      }),
      catchError((response) => {
        this.store.dispatch(OpenShiftAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public add(scheduleData, notify = false, users = []): Observable<any> {
    return this.getSavePeriod(scheduleData.date).pipe(
      switchMap((period) => this._add(scheduleData, notify, users, period)),
    );
  }

  private _add(openShiftData, notify = false, users = [], period): Observable<any> {
    const loadingId = uniqueId('loading_');

    const action = OpenShiftAction.add(openShiftData, loadingId);

    return this.api
      .add(
        { OpenShift: openShiftData, Notify: notify, Users: users },
        {
          minDate: period.minDate,
          maxDate: period.maxDate,
        },
        action,
      )
      .pipe(
        tap((response) => {
          this.store.dispatch(OpenShiftAction.addSuccess(response, loadingId));
        }),
        catchError((response) => {
          this.store.dispatch(undo(action));
          return observableThrowError(response);
        }),
      );
  }

  public update(occurrenceId, openShiftData, scope: ScheduleScope, notify = false, users = []) {
    return this.getSavePeriod(openShiftData.date).pipe(
      switchMap((period) => this._update(occurrenceId, openShiftData, scope, notify, users, period)),
    );
  }

  private _update(occurrenceId, openShiftData, scope: ScheduleScope, notify: boolean, users, period) {
    const loadingId = uniqueId('loading_');

    const action = OpenShiftAction.update(openShiftData, occurrenceId, scope, loadingId);

    return this.api
      .update(
        occurrenceId,
        {
          OpenShift: openShiftData,
          Notify: notify,
          Users: users,
        },
        period,
        scope,
        action,
      )
      .pipe(
        tap((response) => {
          this.store.dispatch(OpenShiftAction.updateSuccess(response, loadingId, scope === 'sequence'));
        }),
        catchError((response) => {
          this.store.dispatch(undo(action));

          return observableThrowError(response);
        }),
      );
  }

  public fetch(occurrenceId) {
    return this.api.fetch(occurrenceId, OpenShiftAction.fetch(occurrenceId)).pipe(
      tap((response) => {
        this.store.dispatch(OpenShiftAction.fetchSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(OpenShiftAction.fetchFailed(occurrenceId, response));
        return observableThrowError(response);
      }),
    );
  }

  public remove(occurrenceId, scope: ScheduleScope, optimizedData?: OptimizedStoreItemData) {
    const action = OpenShiftAction.remove(occurrenceId, scope, optimizedData);

    return this.api.remove(occurrenceId, scope, action).pipe(
      tap((response) => {
        this.store.dispatch(OpenShiftAction.removeSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(undo(action));
        return observableThrowError(response);
      }),
    );
  }

  public save(openShiftData, scope?: ScheduleScope) {
    if (openShiftData.id) {
      return this.update(openShiftData.id, openShiftData, scope);
    }

    openShiftData = u.omit('id', openShiftData);

    return this.add(openShiftData);
  }

  public takeShift(occurrenceId: string, openShift: OpenShiftModel, userId: string) {
    return this.api.takeShift(occurrenceId, OpenShiftAction.takeShift(occurrenceId, openShift.team_id)).pipe(
      tap((roster) => {
        this.store.dispatch(
          OpenShiftAction.takeShiftSuccess(occurrenceId, roster, {
            employeeIds: [userId],
            departmentIds: [openShift.department_id],
          }),
        );
      }),
      catchError((response) => {
        this.store.dispatch(OpenShiftAction.takeShiftFailed(occurrenceId, response));
        return observableThrowError(response);
      }),
    );
  }

  public rejectShift(occurrenceId, openShiftData) {
    return this.api.rejectShift(openShiftData, OpenShiftAction.rejectShift(openShiftData)).pipe(
      tap((openShift) => {
        this.store.dispatch(OpenShiftAction.rejectShiftSuccess(occurrenceId, openShift));
      }),
      catchError((response) => {
        this.store.dispatch(OpenShiftAction.rejectShiftFailed(occurrenceId, response));

        return observableThrowError(response);
      }),
    );
  }

  public requestShift(occurrenceId) {
    return this.api.requestShift(occurrenceId, OpenShiftAction.requestShift(occurrenceId)).pipe(
      tap((openShift) => {
        this.store.dispatch(OpenShiftAction.requestShiftSuccess(occurrenceId, openShift));
      }),
      catchError((response) => {
        this.store.dispatch(OpenShiftAction.requestShiftFailed(occurrenceId, response));

        return observableThrowError(response);
      }),
    );
  }

  public assign(occurrenceId, scheduleData) {
    const loadingId = uniqueId('loading_');

    const action = OpenShiftAction.assign(scheduleData, occurrenceId, loadingId);

    const openShiftData = {
      user_id: scheduleData.user_id,
      team_id: scheduleData.team_id,
      date: scheduleData.date,
      notify: scheduleData.notify ? scheduleData.notify : false,
    };

    return this.api.assign(occurrenceId, openShiftData, action).pipe(
      tap((response) => {
        this.store.dispatch(
          OpenShiftAction.assignSuccess(response, loadingId, {
            occurrenceId,
            teamId: openShiftData.team_id,
            employeeIds: [openShiftData.user_id],
            departmentIds: [scheduleData.department_id],
          }),
        );
      }),
      catchError((response) => {
        this.store.dispatch(undo(action));

        return observableThrowError(response);
      }),
    );
  }

  public multiAssign(
    occurrenceId: string,
    data: OpenShiftMultiAssignRequestModel,
    teamId: string,
    departmentId: string,
  ) {
    const loadingId = uniqueId('loading_');

    const action = OpenShiftAction.multiAssign(
      { employeeIds: data.userIds, teamId, departmentId },
      occurrenceId,
      loadingId,
    );

    return this.api.multiAssign(occurrenceId, data, action).pipe(
      tap((response) => {
        this.store.dispatch(
          OpenShiftAction.multiAssignSuccess(response, loadingId, {
            occurrenceId,
            teamId: teamId,
            employeeIds: data.userIds,
            departmentIds: [departmentId],
          }),
        );
      }),
      catchError((response) => {
        this.store.dispatch(undo(action));

        return observableThrowError(response);
      }),
    );
  }

  public withdraw(occurrenceId) {
    return this.api.withdraw(occurrenceId, OpenShiftAction.withdraw(occurrenceId)).pipe(
      tap((openShift) => {
        this.store.dispatch(OpenShiftAction.withdrawSuccess(occurrenceId, openShift));
      }),
      catchError((response) => {
        this.store.dispatch(OpenShiftAction.withdrawFailed(occurrenceId, response));

        return observableThrowError(response);
      }),
    );
  }
}
